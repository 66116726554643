import Link from 'next/link';

const texts = {
    fr: {
        title: '404 - Cette page n’existe pas',
        description: 'La page que vous recherchez semble introuvable.',
        link: 'Retourner à la page d’accueil',
    },
    en: {
        title: '404 - This page could not be found.',
        description: 'The page you are looking for seems to be missing.',
        link: 'Go back to the homepage',
    },
};

const Custom404 = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-4xl font-bold mb-2">{texts.fr.title}</h1>
            <p className="text-lg mb-12">{texts.fr.description}</p>
            <Link href="/">
                <p className="bg-orange-500 text-white font-bold text-md py-2 px-4 rounded">
                    {texts.fr.link}
                </p>
            </Link>
        </div>
    );
};

export default Custom404;
